import React from 'react';
import { Link } from 'gatsby';
import {
    LevelContainer,
    LevelImage,
    LevelTitle,
} from './styled';

import {
    Container,
    Heading,
    Center,
    Grid,
} from '../../../pageStyles'

const data = [
    {name: "Principiante", slug: "/beginner", image: "/images/beginner.jpg"},
    {name: "Intermedio", slug: "/intermedio", image: "/images/intermediate.jpg"},
    {name: "Esperto", slug: "", image: "/images/expert.jpg"},
]

export const Levels = () => {

    return (
        <Container>
            <Center>
              <Heading>
                Livello
              </Heading>
            </Center>
            <Grid>
              {data.map(lev => 
                <LevelContainer href={lev.slug}>
                    <LevelImage src={lev.image}>
                        <LevelTitle>
                            {lev.name}
                        </LevelTitle>
                    </LevelImage>
                </LevelContainer>
                )}
            </Grid>
          </Container>
    );
}

