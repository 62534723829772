import styled from 'styled-components';
import { colors, fonts, media } from '../../../utils';

export const LevelContainer = styled.a`
    text-decoration: none;
    color: ${colors.white};

    &:hover{ 
        text-decoration: none;
    }
`;

export const LevelImage = styled.div`
    border-radius: 2rem;
    margin: 0 0 10px 0;
    height: 200px;
    background: ${colors.black} no-repeat center center;
    background-image: url("${props => props.src}");
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.35);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const LevelTitle = styled(fonts.Heading)`
    color: ${colors.white} !important;
    margin: 0;
`;