import React from 'react';
import { Link, graphql } from 'gatsby';

import { Layout, SEO } from '../components/common';
import { TelegramChannel } from '../components/common/Promo'

import { Levels } from '../components/blog/Levels'
import { Tags } from '../components/blog/Tags'
import { Categories } from '../components/blog/Categories'

import {
    Container,
    PostList,
    PostListItem,
    Date,
    SubHeading,
    Heading,
    Preview,
    Content,
    Thumbnail,
    Text,
    PaginationContainer,
    PageNext,
    PagePrev,
} from '../pageStyles/AllPostStyle';

import {
  Paragraph,
  Center,
  Divider,
} from '../pageStyles'

const Index = ({ data, pageContext }) => {
  const { currentPage, numPages, tags, categories } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()
    const posts = data.allMarkdownRemark.edges;

    return (
        <Layout promotion={<TelegramChannel/>}>
          <SEO title="Tutorial utili per i droni FPV | NorthFPV" />
          <Container>
            <Center>
            <Paragraph>
              Per aiutarti a cercare l'articolo che ti serve, abbiamo organizzato i contenuti in diverse categorie.
            </Paragraph>
            </Center>
          </Container>
          <Levels/>
          <Divider/>
          <Categories list={categories}/>
          <Divider/>
          <Container>
            <Center>
              <Heading>
                Gli ultimi articoli
              </Heading>
            </Center>
          </Container>
          <Container>
            <PostList>
                {posts.map(( node, index ) => {
                    const { node : { frontmatter : { title, date, excerpt, author, ogimage}, fields : { slug } } } = node;
                    return (
                        <PostListItem>
                            <Link to={`${slug}`} key={index}>
                              <Content>
                                <Thumbnail src={ogimage ? ogimage : '/images/logo.png'}/>
                                <Text>
                                  <Date>
                                      {date} &nbsp;&middot;&nbsp; @{author}
                                  </Date>
                                  <SubHeading>
                                      {title}
                                  </SubHeading>
                                  <Preview>
                                      {excerpt}
                                  </Preview>
                                </Text>
                            </Content>
                          </Link>
                        </PostListItem>
                    )
                })}
              <PaginationContainer>
                {!isFirst ? 
                  <PagePrev href={`https://northfpv.com/tutorial/${prevPage}`} rel="prev">
                    ← Pagina precedente
                  </PagePrev>
                : <PagePrev/>}
                {!isLast && (
                  <PageNext href={`https://northfpv.com//tutorial/${nextPage}`} rel="next">
                    Pagina successiva →
                  </PageNext>
                )}
              </PaginationContainer>
            </PostList>
            <Divider/>
            <Tags list={tags}/>
          </Container>
        </Layout>
        
        
    )
}

export default Index;

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale:"it")
            title
            excerpt
            author
            ogimage
          }
        }
      }
    }
  }
`